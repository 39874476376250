<template>
  <div>
    <b-card class="hoverCard shadow border-bottom border-left" @click="entityRoute">
      <b-row align-h="between" class="">
        <b-card-title class="ml-1 font-small-4 font-weight-bolder">
          {{ entity.name }}
        </b-card-title>
        <b-card-sub-title class="mr-1 pt-75 font-small-4 font-weight-bolder">
          <span class="text-primary">{{ entity.validity }}</span>
        </b-card-sub-title>
      </b-row>
      <b-card-text>
        <span class="text-muted font-weight-bold font-small-2">Qualified name:</span>
        {{ entity.qualified_name }}
      </b-card-text>
    <!--<pre>{{ entity }}</pre>-->
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  computed: {
    entityType() {
      return this.entity.cpt_labels.filter(e => e !== 'Component')
    },
  },
  methods: {
    entityRoute() {
      this.$store.dispatch('domainModel/selectEntity2', this.entity.id)
        .then(() => {
          this.$router.push({
            name: 'domain_ontology_focus',
            params: {
              focus: this.entity.id,
            },
          })
        })
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      default:
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .dark-layout {
    .hoverCard:hover {
      background-color: #343e5a;
    }
  }

  .hoverCard:hover {
    background-color: #e5e5e5;
    cursor: pointer;
  }
</style>
