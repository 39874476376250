<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Model Statistics</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body d-inline-flex justify-content-between" style="padding: 0 2.4rem 1.8rem !important;">
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-success"
          >
            <feather-icon
              size="24"
              icon="CheckSquareIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ requirementsCount }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Requirements
          </b-card-text>
        </b-media-body>
      </b-media>
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-danger"
          >
            <feather-icon
              size="24"
              icon="LoaderIcon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ issuesCount }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Active Issues
          </b-card-text>
        </b-media-body>
      </b-media>
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-warning"
          >
            <font-awesome-icon
              :icon="['fas', 'sitemap']"
              class="font-medium-5"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ behaviourNodesCount }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Behaviour Nodes
          </b-card-text>
        </b-media-body>
      </b-media>
      <b-media no-body class="my-auto">
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            variant="light-info"
          >
            <font-awesome-icon
              :icon="['fas', 'vials']"
              class="font-medium-5"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ testsCount }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            Test Cases
          </b-card-text>
        </b-media-body>
      </b-media>
      <!--<b-media no-body>-->
      <!--  <b-media-aside-->
      <!--    class="mr-2"-->
      <!--  >-->
      <!--    <b-avatar-->
      <!--      size="48"-->
      <!--      variant="light-info"-->
      <!--    >-->
      <!--      <font-awesome-icon-->
      <!--        :icon="['far', 'lightbulb']"-->
      <!--        class="font-medium-5"-->
      <!--      />-->
      <!--    </b-avatar>-->
      <!--  </b-media-aside>-->
      <!--  <b-media-body class="my-auto">-->
      <!--    <h4 class="font-weight-bolder mb-0">-->
      <!--      {{ ideasCount }}-->
      <!--    </h4>-->
      <!--    <b-card-text class="font-small-3 mb-0">-->
      <!--      Ideas-->
      <!--    </b-card-text>-->
      <!--  </b-media-body>-->
      <!--</b-media>-->
    </b-card-body>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      requirementsCount: 0,
      issuesCount: 0,
      testsCount: 0,
      behaviourNodesCount: 0,
      ideasCount: 0,
    }
  },
  mounted() {
    this.getAllCounts()
  },
  methods: {
    getAllCounts() {
      axiosIns
        .get(`/api/v2/models/${this.$store.state.model.id}/stats`)
        .then(({ data }) => {
          this.requirementsCount = data.requirements
          this.issuesCount = data.issues
          this.testsCount = data.tests
          this.behaviourNodesCount = data.behaviourNodes
          this.ideasCount = data.ideas
        })
    },
  },
}
</script>
