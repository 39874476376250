<template>
  <div>
    <b-card class="hoverCard shadow border-bottom border-left" @click="requirementRoute">
      <b-row align-h="between" class="">
        <b-card-title class="ml-1 mt-50 font-small-4 font-weight-bolder">
          {{ requirement.display_id }}
        </b-card-title>
        <b-card-sub-title class="mr-1 mt-50 font-small-4 font-weight-bolder">
          <span class="text-primary">{{ requirement.priority }}</span>
        </b-card-sub-title>
      </b-row>
      <b-card-text v-html="requirement.object_text" />
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    requirement: {
      type: Object,
      default: null,
    },
  },
  methods: {
    requirementRoute() {
      this.$router.push({
        name: 'legacy_requirements_table',
        params: { specId: this.requirement.spec_id },
        query: {
          focus: this.requirement.id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .dark-layout {
    .hoverCard:hover {
      background-color: #343e5a;
    }
  }

  .hoverCard:hover {
    background-color: #e5e5e5;
    cursor: pointer;
  }
</style>
