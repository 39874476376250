<template>
  <b-modal
    id="modal-update-model"
    centered
    size="lg"
    title="Update Model"
    ok-title="Update Model"
    @ok="onSubmit"
    @shown="reset"
  >
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-1"
        label="Model Description:"
        label-for="model-description"
        description="Update the model description"
      >
        <b-form-textarea
          id="model-description"
          :value="description"
          placeholder="Describe the model"
          rows="6"
          required
        />
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Model Scope:"
        label-for="model-scope"
        description="Update the model scope"
      >
        <b-form-textarea
          id="model-scope"
          :value="scope"
          placeholder="Define the scope of the project"
          rows="6"
          required
        />
      </b-form-group>

      <b-form-group>
        <LabelWithHoverIcon
          label-string="Security Classification"
          popover-text="Select which default Security Classification you wish to use."
        />

        <b-form-select
          v-model="classification"
          :options="security_classification_options"
        />
      </b-form-group>

      <b-form-group
        id="shared-users-model"
        label="Shared With:"
        label-for="model-shared"
        description="Manage share access"
      >
        <b-list-group id="model-shared">
          <b-list-group-item
            v-for="(user, index) in shared"
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >
            {{ user }}
            <b-button
              class="ml-auto"
              size="sm"
              variant="outline-danger"
              @click="shared.splice(index, 1)"
            >
              <feather-icon
                icon="TrashIcon"
                size="14"
              />
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
      <b-form-group
        id="change-owners-model"
        label="Current owner:"
        label-for="current-owner"
      >
        <b-row no-gutters>
          <b-col>
            <b-form-input
              v-if="isOwnerEdit"
              v-model="owner"
            />

            <p v-else>
              {{ $store.state.model.owner }}
            </p>
          </b-col>
          <b-col cols="3">
            <b-button
              v-if="$store.state.model.owner === $store.state.auth.email && !isOwnerEdit"
              variant="outline-primary"
              class="float-right"
              @click="isOwnerEdit = true"
            >
              Change Ownership
            </b-button>

            <b-button
              v-if="isOwnerEdit"
              variant="outline-success"
              class="float-right"
              :disabled="owner === $store.state.model.owner"
              @click="changeOwner"
            >
              Update Owner
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="isOwnerEdit" class="mt-1" no-gutters>
          <b-checkbox v-model="isPreviousOwnerShared" />
          <LabelWithHoverIcon
            class="ml-25"
            popover-text="Share the model with your account, otherwise you will lose access to this model after migrating ownership."
          >
            <template v-slot:label-text>
              <span class="mr-50">Share Model with myself</span>
            </template>
          </LabelWithHoverIcon>
        </b-row>
      </b-form-group>
    </b-form>
    <!--<pre>{{ $store.state.auth.email }}</pre>-->
    <!--<pre>{{ shared }}</pre>-->
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UpdateModel',
  components: {
    LabelWithHoverIcon,
  },
  data() {
    return {
      isOwnerEdit: false,
      owner: '',
      isPreviousOwnerShared: true,
      classification: this.$store.state.model.defaultSecurityClassification,
    }
  },
  computed: {
    description() {
      return this.$store.state.model.description
    },
    ...mapGetters({
      security_classification_options: 'constants/securityClassifications',
    }),
    scope() {
      return this.$store.state.model.scope
    },
    // classification() {
    //   return this.$store.state.model.defaultSecurityClassification
    // },
    shared() {
      return this.$store.state.model.shared_with
    },
  },
  methods: {
    onSubmit() {
      const params = {
        model_id: this.$store.state.model.id,
        description: document.getElementById('model-description').value,
        scope: document.getElementById('model-scope').value,
        default_security_classification: this.classification,
        shared_with: this.shared,
      }
      this.$http.post('/api/v2/models/update', params)
        .then(() => {
          this.$store.commit('model/updateModelDescription', params.description)
          this.$store.commit('model/updateModelScope', params.scope)
          this.$store.commit('model/updateModelShared', params.shared_with)
          this.$store.commit('model/updateModelDefaultSecurityClassification', params.default_security_classification)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(response => {
          console.error(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update model',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeOwner() {
      const payload = {
        model: this.$store.state.model.id,
        new_owner: this.owner,
        share_with_current_owner: this.isPreviousOwnerShared,
      }
      this.$http
        .patch('/api/v2/models/change_ownership', payload)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model Ownership Changed',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({
            name: 'model_browse',
          })
          this.$store.dispatch('model/closeWorkspace')
          this.$bvModal.hide('modal-update-model')
        })
        .catch(e => {
          console.error(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to change ownership',
              text: `An error occurred when attempting to change ownership.
              Server returned Status ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    reset() {
      this.isOwnerEdit = false
      this.owner = this.$store.state.model.owner
      this.classification = this.$store.state.model.defaultSecurityClassification
    },
  },
}
</script>
