<template>
  <div id="WorkspaceHeader" class="mb-2">
    <div class="w-100 d-inline-flex justify-content-between">
      <!-- Left -->
      <div>
        <h1 class="display-4 text-primary">
          {{ this.$store.state.model.name }}
          <span class="font-large-1">({{ this.$store.state.model.key }})</span>
        </h1>
      </div>

      <!-- Right -->
      <div class="d-inline-flex justify-content-end">
        <!-- Action Buttons -->
        <div
          v-if="$store.state.model.id"
          class="text-md-right d-md-inline-flex d-none mb-0"
        >
          <b-dropdown
            v-if="this.$route.name === 'model_dashboard' && loggedInUserIsModelOwner()"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            right
          >
            <template #button-content>
              <feather-icon
                icon="Share2Icon"
                size="16"
                class="align-middle mr-1"
              />
              Share
            </template>

            <b-dropdown-item @click="shareWithUser">
              <feather-icon icon="Share2Icon" />
              <span class="align-middle ml-50">Share Model</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!this.$store.state.model.public"
              @click="sharePublic"
            >
              <feather-icon icon="GlobeIcon" />
              <span class="align-middle ml-50">Share Public</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              @click="unsharePublic"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Unshare Public</span>
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            right
            class="mr-1"
          >
            <template #button-content>
              <feather-icon icon="SettingsIcon" class="mr-50" />
              Actions
            </template>

            <b-dropdown-item @click="updateModel">
              <feather-icon
                icon="Edit3Icon"
                size="16"
                class="align-middle mr-1"
              />
              Model Details
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$store.state.model.id && $store.state.model.database && loggedInUserIsModelOwner"
              @click="openMigrateSnapshotModal"
            >
              <feather-icon
                icon="FastForwardIcon"
                size="16"
                class="align-middle mr-1"
              />
              Convert to Model
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item
              v-if="$store.state.model.id && !$store.state.model.database"
              @click="linkModel"
            >
              <feather-icon
                icon="LinkIcon"
                size="16"
                class="align-middle mr-1"
              />
              Create Inter-Model Link
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$store.state.model.id && !$store.state.model.database"
              @click="manageSnapshots"
            >
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="align-middle mr-1"
              />
              Manage Snapshots
            </b-dropdown-item>

            <b-dropdown-item @click="modelHistory">
              <font-awesome-icon
                :icon="['fas', 'history']"
                style="margin-right: 1.1rem;"
              />
              Model History
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$store.state.model.id && loggedInUserIsModelOwner"
              @click="$bvModal.show('modal-delete-model')"
            >
              <feather-icon
                icon="TrashIcon"
                class="text-danger"
                style="margin-right: 1.4rem;"
              />
              <span class="text-danger">Delete Model</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$store.state.model.id && $store.state.model.database && loggedInUserIsModelOwner"
              @click="deleteTempModel"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="align-middle mr-1 text-danger"
              />
              <span class="text-danger">Delete Snapshot</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <!-- Model Information -->
        <div class="d-flex flex-column justify-content-between text-muted">
          <div>
            <small class="mr-75">Owner:</small>
            <small class="select-all">{{ this.$store.state.model.owner }}</small>
          </div>

          <div>
            <small class="mr-75">Created:</small>
            <small class="select-all">{{ this.$store.state.model.created | formatDate }}</small>
          </div>

          <div>
            <small class="mr-75">UUID:</small>
            <small class="select-all">{{ this.$store.state.model.id }}</small>
          </div>
        </div>
      </div>
    </div>
    <hr>

    <div class="d-none">
      <modal-close-model-workspace />
      <modal-update-model />
      <modal-delete-model />
      <modal-share-model />
      <modal-share-public-model />
      <modal-unshare-public-model />
      <div v-if="$store.state.model.id && $store.state.model.database">
        <delete-temp-model-modal />
        <migrate-snapshot-to-live-model-modal />
      </div>
      <!--<modal-export-model />-->
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ModalCloseModelWorkspace from '@/components/Models/Modals/CloseWorkspace.vue'
import ModalShareModel from '@/components/Models/Modals/Share.vue'
import ModalSharePublicModel from '@/components/Models/Modals/SharePublic.vue'
import ModalUnsharePublicModel from '@/components/Models/Modals/UnsharePublic.vue'
import ModalUpdateModel from '@/components/Models/Modals/Update.vue'
import MigrateSnapshotToLiveModelModal from '@/views/Models/MigrateSnapshotToLiveModel.vue'
import DeleteTempModelModal from '@/views/Models/DeleteTempModel.vue'
import ModalDeleteModel from '@/components/Models/Modals/DeleteModel.vue'
// import ModalExportModel from '@/components/Models/Modals/Export.vue'

export default {
  name: 'WorkspaceHeader',
  directives: {
    Ripple,
  },
  components: {
    DeleteTempModelModal,
    ModalDeleteModel,
    MigrateSnapshotToLiveModelModal,
    ModalCloseModelWorkspace,
    // ModalExportModel,
    ModalUpdateModel,
    ModalSharePublicModel,
    ModalUnsharePublicModel,
    ModalShareModel,
  },
  methods: {
    loggedInUserIsModelOwner() {
      return this.$store.state.auth.email === this.$store.state.model.owner
    },
    loggedInUserIsAdministrator() {
      return this.$store.state.auth.roles.includes('administrator')
    },
    manageSnapshots() {
      this.$router.push(
        {
          name: 'manage_model_snapshots',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
          },
        },
      )
    },
    modelHistory() {
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: this.$store.state.model.id,
          },
        },
      )
    },
    linkModel() {
      this.$router.push(
        {
          name: 'model_links',
          params: {
            modelId: this.$store.state.model.id,
          },
        },
      )
    },
    openMigrateSnapshotModal() {
      this.$bvModal.show('modal-migrate-snapshot-to-live-model')
    },
    deleteTempModel() {
      this.$bvModal.show('delete-temp-model-modal')
    },
    updateModel() {
      this.$bvModal.show('modal-update-model')
    },
    shareWithUser() {
      this.$bvModal.show('modal-share-model')
    },
    sharePublic() {
      this.$bvModal.show('modal-share-public-model')
    },
    unsharePublic() {
      this.$bvModal.show('modal-unshare-public-model')
    },
  },
}
</script>
