<template>
  <b-modal
    id="allocate-issues-components-modal"
    title="Allocate Components"
    size="lg"
    @ok="submitFunction"
    @shown="syncAllocations"
  >
    <b-row>
      <b-col cols="5">
        <b-form-group label="All Components:">
          <div v-if="comp_tree.length === 0" class="text-center">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
          <b-form-select v-else v-model="selected_comp" :options="comp_tree" :select-size="5" multiple />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-button-group class="mt-5">
          <b-button
            :disabled="selected_allocated_comp.length === 0"
            @click="removeAllocated"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button
            :disabled="selected_comp.length === 0"
            @click="addAllocated"
          >
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>

      <b-col cols="5">
        <b-form-group label="Allocated Components:">
          <div v-if="allocated_comp.length === 0" class="text-center">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
          <b-form-select v-else v-model="selected_allocated_comp" :options="allocated_comp" :select-size="5" multiple />
        </b-form-group>

      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="primary" :disabled="loading_status" @click="ok()">

        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Allocating...
        </span>

        <span v-else>
          Allocate
        </span>

      </b-button>

      <b-button @click="cancel()">
        Cancel
      </b-button>

    </template>
  </b-modal>
</template>
<script>

import { mapState } from 'vuex'

export default {
  data: () => ({
    loading_status: false,
    selected_comp: [],
    selected_allocated_comp: [],
    comp_tree: [],
    allocated_comp: [],
  }),
  computed: {
    ...mapState('issues', ['selected_issue']),
  },
  methods: {
    submitFunction(evt) {
      const selectedId = this.selected_issue.id
      const modelId = this.$store.state.model.id
      evt.preventDefault()
      this.loading_status = true
      this.$http.post('/api/v2/issues/disassociate_all_issue_components', { issue: selectedId, model: modelId }).then(() => {
        this.$http.post('/api/v2/issues/associate_issue_with_multiple_components', {
          model: modelId,
          issue: selectedId,
          components: this.allocated_comp.map(x => x.value[0]),
        }).then(() => {
          this.$store.dispatch('issues/selectIssue', selectedId)
          this.loading_status = false
          this.$bvModal.hide('allocate-issues-components-modal')
        })
      })
    },
    syncAllocations() {
      const modelId = this.$store.state.model.id
      // TODO: Remove after fixed API
      this.$http.get('/api/v2/domain_model/composition', { params: { model: modelId } })
        .then(({ data }) => {
          this.comp_tree = [
            {
              value: null,
              text: 'Please select an option',
              disabled: true,
            },
            ...data.components.map(x => ({
              value: [x.id, x.qualified_name],
              text: x.qualified_name,
            })),
          ]
        })
      this.allocated_comp = this.selected_issue.components.map(obj => ({
        value: [obj.id, obj.qualified_name],
        text: obj.qualified_name,
      }))
    },
    addAllocated() {
      this.selected_comp.forEach(e => {
        // console.debug('Test', e)
        // console.debug('Test 2', this.selected_comp)
        if (this.allocated_comp.indexOf(e) === -1) {
          this.allocated_comp.push({
            value: e,
            text: e[1],
          })
        }
      })
      this.selected_comp = []
    },
    removeAllocated() {
      this.selected_allocated_comp.forEach(e => {
        this.allocated_comp.splice(this.allocated_comp.indexOf(e), 1)
      })
      this.selected_allocated_comp = []
    },
  },
}
</script>
