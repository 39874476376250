<template>
  <div>
    <b-modal
      id="modal-share-public-model"
      centered
      title="Publicly Share Model"
      ok-title="Set Public"
      ok-variant="danger"
      @ok="shareModel"
    >
      <span>
        Setting this model to be shared publicly will allow any logged in user to
        <span class="font-weight-bold text-danger">access and modify</span>
        this model.
      </span>
      <p class="mt-1 font-weight-bold">
        Do you wish to proceed?
      </p>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PubliclyShareModel',
  methods: {
    shareModel() {
      const params = {
        model_id: this.$store.state.model.id,
      }
      this.$http.post('/api/v2/models/share_public', params)
        .then(data => {
          this.$store.commit('model/setPublicShareState', true)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Publicly Shared Model',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Successfully set model to Public',
            },
          })
        })
        .catch(data => {
          console.error(data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error publicly sharing model',
              icon: 'CrossIcon',
              variant: 'danger',
              text: 'Failed to publicly share model',
            },
          })
        })
    },
  },
}
</script>
