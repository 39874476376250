<template>
  <b-modal
    id="allocate-issues-requirements-modal"
    title="Associate Requirements"
    size="lg"
    @ok="submitFunction"
    @shown="syncAllocations"
  >
    <b-row>
      <b-col cols="5">
        <b-form-group label="All Requirements:">
          <div v-if="all_req.is" class="text-center">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
          <b-form-select v-else v-model="selected_req" :options="all_req" :select-size="5" multiple />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-button-group class="mt-5">
          <b-button
            :disabled="selected_allocated_req.length === 0"
            variant="danger"
            @click="removeAllocated"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button
            :disabled="selected_req.length === 0"
            variant="success"
            @click="addAllocated"
          >
            <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>

      <b-col cols="5">
        <b-form-group label="Associated Requirements:">
          <div v-if="all_req.length === 0" class="text-center">
            <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
          </div>
          <b-form-select v-else v-model="selected_allocated_req" :options="allocated_req" :select-size="5" multiple />
        </b-form-group>

      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-danger" @click="cancel()">
        Cancel
      </b-button>

      <b-button variant="success" :disabled="loading_status || all_req.length === 0" @click="ok()">

        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Associating...
        </span>

        <span v-else>
          Associate Requirements
        </span>
      </b-button>
    </template>
  </b-modal>
</template>
<script>

import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    reqs: {
      type: Array,
      default: Array,
    },
  },
  data: () => ({
    loading_status: false,
    selected_req: [],
    selected_allocated_req: [],
    all_req: [],
    allocated_req: [],
  }),
  computed: {
    ...mapState('issues', ['selected_issue']),
  },
  methods: {
    submitFunction(evt) {
      const selectedId = this.selected_issue.id
      const allocatedReqIds = []
      this.allocated_req.forEach(e => {
        allocatedReqIds.push(e.value[0])
      })
      evt.preventDefault()
      this.loading_status = true
      this.$http
        .post('/api/v2/issues/disassociate_all_issue_requirements', { issues: [selectedId] })
        .then(() => {
          this.$http
            .post('/api/v2/issues/associate_issues_and_requirements', {
              issues: [selectedId],
              requirements: allocatedReqIds,
            })
            .then(() => {
              this.$store.dispatch('issues/selectIssue', selectedId)
              this.loading_status = false
              this.$bvModal.hide('allocate-issues-requirements-modal')
            })
        })
    },
    syncAllocations() {
      const params = {
        model: this.$store.state.model.id,
      }
      this.$http
        .get('/api/v2/requirements/get_requirements_simple', { params })
        .then(({ data }) => {
          this.all_req = data.map(x => ({
            value: [x.id, x.display_id],
            text: x.display_id,
          }))
        })
      this.allocated_req = this.selected_issue.requirements.map(x => ({
        value: [x.id, x.display_id],
        text: x.display_id,
      }))
    },
    addAllocated() {
      this.selected_req.forEach(e => {
        if (this.allocated_req.indexOf(e) === -1) {
          this.allocated_req.push({
            value: e,
            text: e[1],
          })
        }
      })
      this.selected_req = []
    },
    removeAllocated() {
      this.selected_allocated_req.forEach(e => {
        this.allocated_req.splice(this.allocated_req.indexOf(e), 1)
      })
      this.selected_allocated_req = []
    },
  },
}
</script>
