<template>
  <div>
    <b-modal
      id="modal-unshare-public-model"
      centered
      title="Unshare Public Model"
      ok-title="Unshare Model"
      ok-variant="success"
      @ok="unshareModel"
    >
      <span>
        Explicitly shared users of this model will still be able to access and modify this model.
      </span>
      <p class="mt-1 font-weight-bold">
        Do you wish to proceed?
      </p>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'UnsharePublicModel',
  methods: {
    unshareModel() {
      const params = {
        model_id: this.$store.state.model.id,
      }
      this.$http.post('/api/v2/models/unshare_public', params)
        .then(data => {
          this.$store.commit('model/setPublicShareState', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unshared Public Model',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Successfully unset model from Public',
            },
          })
        })
        .catch(data => {
          console.error(data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error unsharing public model',
              icon: 'CrossIcon',
              variant: 'danger',
              text: 'Failed to unshare public model',
            },
          })
        })
    },
  },
}
</script>
