<template>
  <div>
    <b-row align-h="between" class="mb-1">
      <b-col>
        <b-button-group>
          <b-button v-b-hover="handleRefreshVariant" :variant="refreshVariant" @click="refreshTable">
            <feather-icon icon="RefreshCwIcon" class="text-primary mr-25" />
            Refresh Table
          </b-button>
          <b-button v-b-hover="handleAddVariant" :variant="addVariant" @click="$bvModal.show('add-issue-modal')">
            <feather-icon icon="PlusIcon" class="text-success" />
            Add Issue
          </b-button>
          <download-csv
            v-b-hover="handleExportVariant"
            :class="exportVariant"
            :data="issues"
            name="issue_data.csv"
          >
            <feather-icon icon="DownloadIcon" class="text-info mr-25" />
            Export Data
          </download-csv>
        </b-button-group>
      </b-col>
    </b-row>

    <b-sidebar
      id="sidebar-1"
      title="Issue Details"
      bg-variant="white"
      shadow="true"
      width="30vw"
      right
      backdrop
      :visible="edit"
      @hidden="edit = false"
    >
      <IssuesSidebar class="mx-2" @updated="edit = false; refreshTable" />
    </b-sidebar>
    <div class="d-flex justify-content-between flex-wrap">
      <b-col class="custom-search" cols="6" style="padding-left: 0">
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-col>
      <div class="d-flex align-items-center mb-1 mt-0">
        <span class="text-nowrap ">
          Rows:
        </span>
        <b-form-select
          v-model="pageLength"
          style="float: right"
          :options="['5','10','15', {
            value: totalIssues,
            text: `All ${totalIssues}`,
          }]"
          class="mx-1"
        />
      </div>
    </div>
    <span class="text-muted mt-1"><em>Double click on a row to edit issue.</em></span>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      @on-row-dblclick="openEditSidebar"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Description -->
        <div v-if="props.column.field === 'name'" class="d-flex flex-column">
          <span class="mb-50" v-html="props.row.name" />
        </div>

        <!-- Column: Classification -->
        <div v-else-if="props.column.field === 'classification'">
          <span>{{ props.row.classification }}</span>
        </div>

        <!-- Column: Severity -->
        <div v-else-if="props.column.field === 'severity'">
          <span>{{ props.row.severity }}</span>
        </div>

        <!-- Column: Status -->
        <div v-else-if="props.column.field === 'status'">
          <span>{{ props.row.status }}</span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-button
              v-b-tooltip.hover.top="'Edit Issue'"
              variant="flat-secondary"
              size="sm"
              class="btn-icon text-success"
              @click="openEditSidebar(props)"
            >
              <feather-icon
                icon="Edit2Icon"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="'Go to Issue Details'"
              variant="flat-secondary"
              size="sm"
              class="btn-icon text-info"
              @click="focusIssue(props)"
            >

              <feather-icon
                icon="MaximizeIcon"
              />
            </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap ml-0">
          <div class="d-flex align-items-center mb-1 mt-1">
            <span class="text-nowrap" style="margin-left: 0.5rem;">Rows: </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','15', {
                value: props.total,
                text: `All ${props.total}`,
              }]"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
          </div>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Displaying {{ pageLength }} of {{ props.total }} Issues </span>
            </div>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <IssueAdd />
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import { mapState } from 'vuex'
import IssuesSidebar from '@/views/Issues/IssuesSidebar.vue'
import IssueAdd from '@/components/Issues/Modals/IssueAdd.vue'

export default {
  name: 'Issues',
  components: {
    VueGoodTable,
    BPagination,
    BFormInput,
    BFormSelect,
    BButton,
    BButtonGroup,
    IssuesSidebar,
    IssueAdd,
  },
  data() {
    return {
      edit: false,
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Issues',
          field: 'name',
        },
        {
          label: 'Description',
          field: 'description',
          hidden: true,
        },
        {
          label: 'Classification',
          field: 'classification',
        },
        {
          label: 'Severity',
          field: 'severity',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'New',
        2: 'Open',
        3: 'Deferred',
        4: 'Withdrawn',
        5: 'Closed',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      refreshVariant: 'flat-secondary',
      addVariant: 'flat-secondary',
      exportVariant: 'btn btn-flat-secondary',
    }
  },
  computed: {
    ...mapState('issues', ['issues']),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        New      : 'light-primary',
        Open : 'light-success',
        Deferred     : 'light-danger',
        Withdrawn     : 'light-warning',
        Closed      : 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    totalIssues() {
      return this.issues.length
    },
  },
  watch: {
    issues(newVal) {
      this.rows = newVal
    },
  },
  created() {
    this.edit = false
    this.refreshTable()
  },
  methods: {
    refreshTable() {
      this.$store.dispatch('issues/getIssues')
        .then(() => {
          this.rows = this.issues
        })
    },
    openEditSidebar(rowVal) {
      this.edit = true
      this.$store.dispatch('issues/selectIssue', rowVal.row.id)
      this.$store.dispatch('comments/getEntityComments', { label: 'Issue', id: rowVal.row.id })
    },
    focusIssue(rowVal) {
      this.$store.dispatch('comments/getEntityComments', { label: 'Issue', id: rowVal.row.id })
      this.$store.dispatch('issues/selectIssue', rowVal.row.id)
        .then(() => {
          this.$router.push({
            name: 'issue_details',
            params: { id: rowVal.row.id },
          })
        })
    },
    deleteIssue(val) {
      console.debug('Test', val)
    },
    handleAddVariant(handler) {
      if (handler) this.addVariant = 'flat-success'
      else this.addVariant = 'flat-secondary'
    },
    handleRefreshVariant(handler) {
      if (handler) this.refreshVariant = 'flat-primary'
      else this.refreshVariant = 'flat-secondary'
    },
    handleExportVariant(handler) {
      if (handler) this.exportVariant = 'btn btn-flat-info'
      else this.exportVariant = 'btn btn-flat-secondary'
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

body.dark-layout .vgt-wrap .vgt-table td, body.dark-layout .vgt-wrap .vgt-table tr {
  background-color: transparent;
}

body.dark-layout .vgt-wrap .vgt-table tr:hover {
  background-color: #343e5a;
}
</style>
