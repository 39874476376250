<template>
  <div>
    <b-card class="hoverCard shadow border-bottom border-left" @click="behaviourRoute">
      <b-card-title class="font-small-4 font-weight-bolder">
        {{ behaviour.name }}
      </b-card-title>
      <b-card-sub-title class="font-small-3 mb-1">
        {{ behaviour.id }}
      </b-card-sub-title>
      <b-card-text>
        <span class="font-weight-bolder">Belongs to behaviour tree(s):</span>
        <ul v-for="bt in behaviour.bts" :key="bt.id">
          <li>{{ bt.name }}</li>
        </ul>
      </b-card-text>
    <!--<pre>{{ behaviour }}</pre>-->
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    behaviour: {
      type: Object,
      default: null,
    },
  },
  methods: {
    behaviourRoute() {
      const bTree = this.behaviour.bts[0].id
      const bNode = this.behaviour.id
      this.$router.push(
        {
          name: 'joint_mbse_tree_focus',
          params: { behaviourTreeId: bTree },
          query: { focus: bNode },
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
  .dark-layout {
    .hoverCard:hover {
      background-color: #343e5a;
    }
  }

  .hoverCard:hover {
    background-color: #e5e5e5;
    cursor: pointer;
  }
</style>
