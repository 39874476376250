<template>
  <b-modal
    id="modal-share-model"
    centered
    title="Share Model"
    ok-title="Share Model"
    @show="clear"
    @ok.prevent="validationForm"
  >
    <validation-observer ref="shareValidation">
      <b-form-group>
        <label>Kompozition user:</label>
        <validation-provider
          #default="{ errors }"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            id="share-email"
            v-model="shareEmail"
            type="email"
            placeholder="Email address"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <b-form-text>
          <b-row class="mx-0 mb-1">
            <span>Share this model with another Kompozition user.</span>
          </b-row>
          <b-row class="mx-0">
            <span class="text-info">Note: Please use a single valid email address. Sharing with multiple users in a single action is not supported.</span>
          </b-row>
        </b-form-text>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import store from '@/store'

export default {
  name: 'ShareModel',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      shareEmail: '',
      required,
      email,
    }
  },
  methods: {
    clear() {
      this.shareEmail = ''
    },
    shareModel() {
      const params = {
        model_id: this.$store.state.model.id,
        share_email: this.shareEmail,
      }
      const existingModelWorkspace = sessionStorage.getItem('kompozition-workspace')
      const tempSnapshotWorkplace = sessionStorage.getItem('X-Target')
      this.$http.post('/api/v2/models/share', params)
        .then(response => {
          if (tempSnapshotWorkplace) {
            const model = {}
            model.id = existingModelWorkspace
            model.database = tempSnapshotWorkplace
            store.dispatch('model/openTempWorkspace', model)
          } else {
            this.$store.dispatch('model/openWorkspace', this.$store.state.model.id)
          }
          this.$bvModal.hide('modal-share-model')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model shared',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data,
            },
          })
        })
        .catch(response => {
          console.error(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error sharing model',
              icon: 'CheckIcon',
              variant: 'danger',
              text: `Failed to share model (${response.data})`,
            },
          })
        })
    },
    validationForm() {
      this.$refs.shareValidation.validate().then(success => {
        if (success) {
          this.$coreService.usersManagementApi.checkUserEmailExists(this.shareEmail)
            .then(response => {
              // console.log('Return data', response)
              if (response) this.shareModel()
              else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error sharing model',
                    icon: 'CheckIcon',
                    variant: 'danger',
                    text: 'Failed to share model (User does not exist)',
                  },
                })
                this.$refs.shareValidation.setErrors({
                  Email: ['User does not exist'],
                })
              }
            })
        }
      })
    },
  },
}
</script>
