<template>
  <div>
    <workspace-header class="mb-2" />

    <b-container>

      <b-row class="match-height">
        <b-col md="7">
          <model-stats />
        </b-col>

        <b-col md="5">
          <b-card title="Description">
            <b-card-text>
              <div class="font-small-3">
                {{ this.$store.state.model.description }}
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-card title="Scope">
            <b-card-text>
              <div v-html="this.$store.state.model.scope" />
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <h3>{{ sectionTitle }}</h3>
      <hr>
      <model-dashboard-cards v-if="sectionTitle === 'All'" />
      <specifications v-if="sectionTitle === 'Specifications'" />
      <issue v-else-if="sectionTitle === 'Issues'" />
      <release v-else-if="sectionTitle === 'Releases'" />
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WorkspaceHeader from '@/layouts/components/Workspace/WorkspaceHeader.vue'
import ModelDashboardCards from '@/views/Models/ModelDashboardCards.vue'
import ModelStats from '@/views/Models/ModelStats.vue'
import Specifications from '@/views/Specifications/Specifications.vue'
import Issue from '@/views/Issues/Issues.vue'
import Release from '@/views/Releases/Releases.vue'

export default {
  name: 'ModelDashboard',
  components: {
    WorkspaceHeader,
    ModelStats,
    ModelDashboardCards,
    Specifications,
    Issue,
    Release,
  },
  computed: {
    ...mapState({
      sectionTitle: state => state.auth.workingOn,
    }),
    sectionTitle() {
      return this.$store.state.auth.workingOn
    },
  },
}
</script>
